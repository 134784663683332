import UploadIcon from '@mui/icons-material/Upload';
import { useState } from "react";
import IconButton from "./IconButton";
import Toast from "../Toast";


interface IIconButton {
    upload: (file: File) => Promise<void>
}

export default function UploadButton(props: IIconButton) {
    const [uploading, setUploading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [successMsg, setSuccessMsg] = useState<string | null>(null);

    const handleChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (!!target.files) {
            try {
                const file = target.files[0];
                setUploading(true);
                await props.upload(file);
                setSuccessMsg(`File '${file.name}' uploaded successfully.`)
            } catch (error) {
                setErrorMsg("Something went wrong. Please check your file and try again.")
            }
            setUploading(false);
        }
    }

    return (
        <>
            <input
                accept='.xlsx'
                type="file"
                id="raised-button-file"
                onChange={handleChange}
                hidden
            />
            <label htmlFor="raised-button-file">
                <IconButton
                    disabled={uploading}
                    size="medium"
                    component="span"
                    icon={<UploadIcon fontSize="large" />}
                    variant='outlined'
                />
            </label>
            <Toast message={errorMsg} onClose={() => setErrorMsg(null)} />
            <Toast message={successMsg} onClose={() => setSuccessMsg(null)} severity='success' />
        </>
    );
}
