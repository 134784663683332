import { Grid } from "@mui/material";
import { Search, DownloadForOffline } from "@mui/icons-material";
import { add, differenceInMinutes, isAfter, isSameMinute } from "date-fns";
import { useState, useEffect } from "react";
import DateTimePicker from "./DateTimePicker";
import { DateTimeRangeForm } from "./types";
import { IconButton } from "../../components/buttons";
import { UseFormReturn } from "react-hook-form";
import TimeFormatSelect from "./TimeFormatSelect";
import { FilterOption } from "../../types/chart";
import { getTimeFormatOptions } from "../../utils/initalData";

interface IDateTimeRangePicker {
  disabled?: boolean;
  form: UseFormReturn<DateTimeRangeForm> | null;
  maxRangeInMinutes: number;
  onSubmit: (values: DateTimeRangeForm) => Promise<void>;
  submitDisabled?: boolean;
  enableTimeFormat?: boolean;
  isLoading?: boolean;
  canDownload?: boolean;
  isDownloading?: boolean;
  getTimeFormatOpts?: (start: Date, end: Date) => FilterOption[];
}

export default function DateTimeRangePicker({
  disabled,
  form,
  maxRangeInMinutes,
  onSubmit,
  submitDisabled,
  enableTimeFormat,
  isLoading,
  canDownload,
  isDownloading,
  getTimeFormatOpts = getTimeFormatOptions,
}: IDateTimeRangePicker) {
  const {
    clearErrors,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, touchedFields, isSubmitted },
    setError,
    setValue,
  } = form ?? { formState: {} };

  const start = watch?.("start");
  const end = watch?.("end");
  const timeFormat = watch?.('timeFormat');

  const submitting = isLoading ? isLoading : isSubmitting;

  const [timeFormatOptions, setTimeFormatOptions] = useState<FilterOption[]>([]);

  // If a start datetime is entered and an end datetime has not,
  // default the end datetime to start datetime plus one hour.
  useEffect(() => {
    if (start && !end && touchedFields?.start && !touchedFields?.end) {
      const startPlusOneHour = add(new Date(start), {
        minutes: maxRangeInMinutes,
      });
      setValue?.("end", startPlusOneHour, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [
    end,
    maxRangeInMinutes,
    setValue,
    start,
    touchedFields?.end,
    touchedFields?.start,
  ]);

  // Start and end datetime validation.
  useEffect(() => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (isSameMinute(startDate, endDate)) {
        setError?.("end", {
          message: "End datetime must not be the same as start datetime.",
        });
      } else if (isAfter(startDate, endDate)) {
        setError?.("end", {
          message: "End datetime must be after start datetime.",
        });
      } else if (differenceInMinutes(endDate, startDate) > maxRangeInMinutes) {
        setError?.("end", {
          message: `End datetime must be within ${maxRangeInMinutes} minutes of start datetime.`,
        });
      } else {
        clearErrors?.("end");
      }

      setTimeFormatOptions(getTimeFormatOpts(startDate, endDate));
    }
    if (isSubmitted && !timeFormat) {
      setError?.("timeFormat", {
        message: "Please select a time format.",
      });
    }
  }, [clearErrors, end, maxRangeInMinutes, setError, start, isSubmitted]);

  return (
    <form onSubmit={handleSubmit?.(onSubmit)}>
      <Grid alignItems="flex-start" container flexWrap="nowrap" spacing={3}>
        <Grid item flexGrow={[1]}>
          <Grid container flexWrap="wrap" spacing={2}>
            <Grid item xs={6} xl={enableTimeFormat ? 4 : 6}>
              <DateTimePicker
                control={control}
                disabled={disabled}
                error={errors?.["start"]?.message}
                fullWidth
                id="start"
                label="Select Start Datetime"
              />
            </Grid>
            <Grid item xs={6} xl={enableTimeFormat ? 4 : 6}>
              <DateTimePicker
                control={control}
                disabled={disabled}
                error={errors?.["end"]?.message}
                fullWidth
                id="end"
                label="Select End Datetime"
              />
            </Grid>
            {enableTimeFormat ? (<Grid item xs={6} xl={enableTimeFormat ? 4 : 6}>
              <TimeFormatSelect
                control={control}
                disabled={disabled}
                error={errors?.["timeFormat"]?.message}
                fullWidth
                id="timeFormat"
                label="Select Time Format"
                options={timeFormatOptions}
              />
            </Grid>) : <></>}
          </Grid>
        </Grid>
        <Grid item flexShrink={0} sx={{ marginTop: 0.5 }} xs="auto">
          <IconButton
            disabled={
              disabled ||
              submitDisabled ||
              !(start && end && !Object.keys(errors ?? {})?.length)
            }
            icon={<Search fontSize="large" />}
            loading={submitting && !form?.getValues("download")}
            size="small"
            type="submit"
            variant="outlined"
            onClick={() => { form?.setValue("download", false); }}
          />
        </Grid>
        {(enableTimeFormat && process.env.REACT_APP_DOWNLOAD) ? (<Grid item flexShrink={0} sx={{ marginTop: 0.5 }} xs="auto">
          <IconButton
            disabled={
              disabled ||
              submitDisabled ||
              !(start && end && !Object.keys(errors ?? {})?.length)
              || !canDownload
              || submitting
            }
            icon={<DownloadForOffline fontSize="large" />}
            loading={isDownloading}
            size="small"
            type="submit"
            variant="outlined"
            onClick={() => { form?.setValue("download", true); }}
          />
        </Grid>) : <></>}
      </Grid>
    </form>
  );
}
