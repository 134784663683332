import { styled, tooltipClasses } from "@mui/material";
import { Overlay } from "ol";
import { RefObject, useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useAuthContext } from "../../context/Auth";
import { useMapContext } from "../../context/Map";
import { createLinkedPointTooltipListener } from "../../utils/features";

const StyledPopup = styled("div")`
  transform: translate(0, calc(8px - 100%));
`;

interface ILinkedPointTooltips {
  overlayRef: RefObject<HTMLDivElement>;
}

export default function LinkedPointTooltips({
  overlayRef,
}: ILinkedPointTooltips) {
  const map = useMapContext();
  const { config } = useAuthContext();
  const content = useRef<HTMLImageElement>(null);
  const selectedUid = useRef<string | null>(null);

  const overlay = useMemo(() => {
    return new Overlay({
      element: overlayRef.current ?? undefined,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
  }, [overlayRef]);

  useEffect(() => {
    if (!map || !overlay) {
      return;
    }

    if (!overlay.getElement() && overlayRef?.current) {
      overlay.setElement(overlayRef?.current);
    }

    map.addOverlay(overlay);

    const onPointerMove = createLinkedPointTooltipListener(
      map,
      overlay,
      content,
      selectedUid,
      config
    );
    map.on("pointermove", onPointerMove);

    return () => {
      map.un("pointermove", onPointerMove);
      overlay.setElement(undefined);
      map.removeOverlay(overlay);
    };
    // eslint-disable-next-line
  }, [map, overlay, overlayRef]);

  return (
    overlayRef?.current &&
    createPortal(
      <StyledPopup className={tooltipClasses.tooltip} id="popup">
        <img alt="info" className="content" id="popup-content" ref={content} />
      </StyledPopup>,
      overlayRef.current
    )
  );
}
