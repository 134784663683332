
import { useState, MouseEvent } from 'react';
import { NestedMenuItem, IconMenuItem } from 'mui-nested-menu';
import { NoFaultType } from '../../types/api';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { BayType } from '../../types/sms';

interface INoFaultMenu {
  rowId: string;
  severityTitle: string | null;
  bayType: BayType;
  handleMenuSelected: (id: string, type: NoFaultType, bayType: BayType) => void;
}

export default function NoFaultMenu({ rowId, severityTitle, bayType, handleMenuSelected }: INoFaultMenu) {
  const [anchorElementPending, setAnchorElementPending] = useState<Element | null>(null)
  const pendingOpen = anchorElementPending ? true : false;

  const [anchorElementEntered, setAnchorElementEntered] = useState<Element | null>(null)
  const enteredOpen = anchorElementEntered ? true : false;

  const [anchorElementNoFault, setAnchorElementNoFault] = useState<Element | null>(null)
  const noFaultOpen = anchorElementNoFault ? true : false;

  const handleClick = (e: MouseEvent) => {
    if (bayType === BayType.PENDING) {
      setAnchorElementPending(e.currentTarget);
    } else if (bayType === BayType.ENTERED) {
      setAnchorElementEntered(e.currentTarget);
    } else {
      setAnchorElementNoFault(e.currentTarget);
    }
  }

  const handleClose = (type: NoFaultType) => {
    if (bayType === BayType.PENDING) {
      setAnchorElementPending(null);
    } else if (bayType === BayType.ENTERED) {
      setAnchorElementEntered(null);
    } else {
      setAnchorElementNoFault(null);
    }
    handleMenuSelected(rowId, type, bayType);
  }

  const severityLabel = (!!severityTitle && severityTitle !== 'none') ? severityTitle : 'TBA';

  return (
    <div>
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        size='small'
        sx={{ color: '#FFFFFF', lineHeight: 1, padding: 0, '&:hover': { backgroundColor: 'unset' } }}
      >
        {severityLabel}
      </Button>
      <Menu anchorEl={anchorElementPending} open={pendingOpen} onClose={handleClose}>
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.low)}
          label='Low'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.medium)}
          label='Medium'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.high)}
          label='High'
        />
        <NestedMenuItem
          rightIcon={<ArrowDropDownIcon />}
          label='No Fault'
          parentMenuOpen={pendingOpen}
        >
          <IconMenuItem
            onClick={() => handleClose(NoFaultType.Mechanical)}
            label='Mechanical'
          />
          <IconMenuItem
            onClick={() => handleClose(NoFaultType.TBD)}
            label='TBD'
          />
        </NestedMenuItem>
      </Menu>

      <Menu anchorEl={anchorElementEntered} open={enteredOpen} onClose={handleClose}>
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.low)}
          label='Low'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.medium)}
          label='Medium'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.high)}
          label='High'
        />
        <NestedMenuItem
          rightIcon={<ArrowDropDownIcon />}
          label='No Fault'
          parentMenuOpen={enteredOpen}
        >
          <IconMenuItem
            onClick={() => handleClose(NoFaultType.Mechanical)}
            label='Mechanical'
          />
          <IconMenuItem
            onClick={() => handleClose(NoFaultType.TBD)}
            label='TBD'
          />
        </NestedMenuItem>
      </Menu>

      <Menu anchorEl={anchorElementNoFault} open={noFaultOpen} onClose={handleClose}>
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.low)}
          label='Low'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.medium)}
          label='Medium'
        />
        <IconMenuItem
          onClick={() => handleClose(NoFaultType.high)}
          label='High'
        />
      </Menu>
    </div>
  );
}
