import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo_large.svg";
import Text from "../../components/form/Text";
import { useAuthContext } from "../../context/Auth";
import { LoginContainer } from "./styled";
import { useState } from "react";
import { UserLogin } from "../../types/user";

const defaultValues = { password: "", username: "" };

export default function LoginPage() {
  const navigate = useNavigate();
  const { login, config } = useAuthContext();
  const [authFailed, setAuthFailed] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<UserLogin>({ defaultValues, mode: "onSubmit" });

  async function handleLogin(values: UserLogin) {
    try {
      await login(values);
      let nav = "/playback";
      if (config.module?.playback) {
        nav = "/playback";
      } else if (config.module?.staticAnalysis) {
        nav = "/static-analysis";
      } else if (config.module?.dashboard) {
          nav = "/analytics";
      }
      navigate(nav);
    } catch (error) {
      setAuthFailed(true);
    }
  }

  return (
    <LoginContainer>
      <div className="logo">
        <img alt="Searidge logo" src={Logo} />
      </div>
      <div className="login">
        <Typography variant="h1">Airfield Insights</Typography>
        <Typography variant="h2">Please sign into your account</Typography>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text
                control={control}
                id="username"
                label="Username"
                rules={{ required: "Required field" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                control={control}
                id="password"
                label="Password"
                rules={{ required: "Required field" }}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
              >
                Sign In
                {isSubmitting && <CircularProgress color="secondary" />}
              </Button>
            </Grid>
            {authFailed && (
              <Grid item xs={12}>
                <FormHelperText error>
                  Authentication has failed.
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </LoginContainer>
  );
}
