import { LayersClear, Thermostat, Timeline } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { DisplayMode } from "./enums";
import { FilterForm } from "./types";
import StaticFilters from "./StaticFilters";
import { IconButton } from "../../components/buttons";
import { DateTimeRangePicker } from "../../components/form";
import { DateTimeRangeForm } from "../../components/form/types";
import {
  HeatmapLayer,
  LinkedPointMarkers,
  LinkedPointTooltips,
  Map,
} from "../../components/map";
import { ControlCard, PageContainer } from "../../components/styled";
import { Table } from "../../components/table";
import { TracksByTrackNumber, TargetsAtSourceTimestamp } from "../../types/map";
import { useTableSelect, useTracks } from "../../hooks";
import { filterTracks } from "../../utils/filters";
import Toast from "../../components/Toast";
import { useWorkerMemo, createWorkerFactory } from "use-worker-promise";
import { useAuthContext } from "../../context/Auth";
import { STATIC_ANALYSIS_PAGE_MAX_TIME_RANGE_DEFAULT_INTERVAL } from "../../utils/defaultValues";
import {
  FloatingLeftGroup,
  FloatingRightGroup,
  FloatingBottomRightGroup,
} from "../../components/styled/FloatingGroups";

const transformTableRowTracksByNumber = createWorkerFactory<
  import("../../utils/workers/tracks/transformTableRowTracksByNumber").Worker
>(
  () =>
    new Worker(
      new URL(
        "../../utils/workers/tracks/transformTableRowTracksByNumber",
        import.meta.url
      ),
      {
        type: "module",
      }
    )
);

const transformStaticAnalysisTracks = createWorkerFactory<
  import("../../utils/workers/tracks/transformStaticAnalysisTracks").Worker
>(
  () =>
    new Worker(
      new URL(
        "../../utils/workers/tracks/transformStaticAnalysisTracks",
        import.meta.url
      ),
      {
        type: "module",
      }
    )
);

const defaultValues = {
  aircraftType: [],
  airline: [],
  callsign: [],
};

function useTransformStaticAnalysisTracks(
  filteredTracks: TargetsAtSourceTimestamp[]
) {
  return useWorkerMemo(transformStaticAnalysisTracks, filteredTracks) || [];
}

function useFilteredTracksByTimestamp(
  tracks: TargetsAtSourceTimestamp[] | null,
  airline: string[],
  aircraftType: string[]
) {
  const filteredTracks = useMemo(
    () =>
      filterTracks(tracks, { Airline: airline, AircraftType: aircraftType }),
    [airline, aircraftType, tracks]
  );

  return useTransformStaticAnalysisTracks(filteredTracks);
}

export default function StaticAnalysisPage() {
  const { config } = useAuthContext();

  const {
    staticAnalysisPage:
      staticAnalysisPageMaxTimerangeInterval = STATIC_ANALYSIS_PAGE_MAX_TIME_RANGE_DEFAULT_INTERVAL,
  } = config?.maxAllowedTimeRangeInterval || {};

  const [displayMode, setDisplayMode] = useState(DisplayMode.LinkedPoint);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { clearErrorMsg, errorMsg, form, fetchTracks, tracks } = useTracks();
  const { control, watch } = useForm<FilterForm>({
    defaultValues,
    mode: "onSubmit",
  });

  const airline = watch("airline");
  const aircraftType = watch("aircraftType");

  async function onSubmit({ start, end }: DateTimeRangeForm) {
    if (start && end) {
      await fetchTracks(start, end);
    }
  }

  // filtered by top-level inputs
  const filteredTracksByTimestamp = useFilteredTracksByTimestamp(
    tracks,
    airline,
    aircraftType
  );

  const trackList =
    useWorkerMemo(transformTableRowTracksByNumber, filteredTracksByTimestamp) ||
    [];

  const { clearSelected, isSelected, onSelect, onSelectAll, selectedTracks } =
    useTableSelect(trackList);

  // selected by the drop-down table
  const selectedTracksByNumber = useMemo(() => {
    if (!selectedTracks?.length) {
      return [];
    }

    if (
      selectedTracks.length === Object.keys(filteredTracksByTimestamp).length
    ) {
      return filteredTracksByTimestamp;
    }

    return Object.entries(filteredTracksByTimestamp).reduce(
      (acc, [trackNumber, tracksAtNumber]) => {
        if (selectedTracks.includes(parseInt(trackNumber))) {
          acc[parseInt(trackNumber)] = tracksAtNumber;
        }

        return acc;
      },
      {} as TracksByTrackNumber
    );
  }, [filteredTracksByTimestamp, selectedTracks]);

  return (
    <PageContainer>
      <ControlCard>
        <Grid
          alignItems="flex-start"
          container
          justifyContent="center"
          spacing={3}
          rowSpacing={3}
        >
          <Grid item xs={12} lg={5}>
            <DateTimeRangePicker
              form={form}
              maxRangeInMinutes={staticAnalysisPageMaxTimerangeInterval}
              onSubmit={onSubmit}
            />
          </Grid>

          <Grid item textAlign="end" xs={3} lg={1}>
            <IconButton
              className={!selectedTracks?.length ? "active" : "inactive"}
              color="error"
              icon={<LayersClear fontSize="large" />}
              onClick={() => clearSelected()}
              variant="outlined"
            />
          </Grid>

          <Grid item flexGrow={[1]} xs={9} lg={5}>
            <StaticFilters control={control} tracks={tracks} />
          </Grid>
        </Grid>
      </ControlCard>

      <Map>
        <FloatingLeftGroup>
          {!!trackList?.length && (
            <Table
              data={trackList}
              isSelected={isSelected}
              numSelected={selectedTracks.length}
              onSelect={onSelect}
              onSelectAll={onSelectAll}
              rowCount={trackList.length}
              rowId="TrackNumber"
            />
          )}
        </FloatingLeftGroup>

        <FloatingRightGroup>
          <IconButton
            className={
              displayMode === DisplayMode.LinkedPoint ? "active" : "inactive"
            }
            fab
            icon={<Timeline fontSize="large" />}
            onClick={() => setDisplayMode(DisplayMode.LinkedPoint)}
            variant="outlined"
          />
          <IconButton
            className={
              displayMode === DisplayMode.Heatmap ? "active" : "inactive"
            }
            fab
            icon={<Thermostat fontSize="large" />}
            onClick={() => setDisplayMode(DisplayMode.Heatmap)}
            variant="outlined"
          />
        </FloatingRightGroup>

        {displayMode === DisplayMode.LinkedPoint && (
          <>
            <LinkedPointMarkers tracksByTrackNumber={selectedTracksByNumber} />
            <LinkedPointTooltips overlayRef={overlayRef} />
          </>
        )}
        <div ref={overlayRef} />

        {displayMode === DisplayMode.Heatmap && (
          <HeatmapLayer tracksByTrackNumber={selectedTracksByNumber} />
        )}
        <FloatingBottomRightGroup>
          <Typography>
            Hold Shift + Alt while dragging to rotate the map
            <br />
            Press the compass arrow to reset view to North
          </Typography>
        </FloatingBottomRightGroup>
        <Toast message={errorMsg} onClose={clearErrorMsg} />
      </Map>
    </PageContainer>
  );
}
