import { ChartOptions, Plugin, TooltipItem } from "chart.js";
import { getRandomColor } from "./colors";

export const chartStyle = {
    color: '#FFFFFF',
    titleSize: 16,
    labels: {
        size: 12,
        pointStyle: 'circle'
    },
    plugins: {
        position: 'right'
    },
    dataColors: [
        { backgroundColor: "#223FC780", borderColor: "#223FC7" },
        { backgroundColor: "#40723980", borderColor: "#407239" },
        { backgroundColor: "#812a5680", borderColor: "#812a56" },
        { backgroundColor: "#22C7A180", borderColor: "#22C7A1" },
        { backgroundColor: "#47303480", borderColor: "#473034" },
        { backgroundColor: "#226AC780", borderColor: "#226AC7" },
        { backgroundColor: "#6022C780", borderColor: "#6022C7" },
        { backgroundColor: "#47363080", borderColor: "#473630" },
        { backgroundColor: "#72703980", borderColor: "#727039" },
        { backgroundColor: "#473F3080", borderColor: "#473F30" },
        // There are only so many contrasting colors we can hand-pick, so randomly generate colors after the 10th
        ...Array.from({ length: 40 }, () => {
            // Asusme white background when calculating contrast
            const randomColor = getRandomColor([255, 255, 255]);

            // Reduce alpha value for background color
            return {
                backgroundColor: `${randomColor}80`,
                borderColor: randomColor,
            }
        })
    ],
    thresholdColors: [
        '#22C669',
        '#fcb93c',
        '#f27044',
        '#bf3c51'
    ]
}

export function getBarOptions(title: string, totalTooltip = true): ChartOptions<'bar'> {
    return {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: chartStyle.color,
                },
                stacked: true,
            },
            x: {
                ticks: {
                    color: chartStyle.color
                },
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    color: chartStyle.color,
                    usePointStyle: true,
                    pointStyle: chartStyle.labels.pointStyle,
                    font: {
                        size: chartStyle.labels.size
                    }
                },
            },
            title: {
                display: true,
                text: title,
                color: chartStyle.color,
                font: {
                    size: chartStyle.titleSize
                }
            },
            ...(totalTooltip ? {
                tooltip: {
                    mode: "index",
                    callbacks: {
                        footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                            return 'Total: ' + tooltipItems.reduce((prev, cur) => prev + cur.parsed.y, 0);
                        }
                    }
                }
            } : {})
        }
    };
}

export function getLineOptions(title: string, text: string): ChartOptions<'line'> {
    return {
        elements: {
            point: {
                hoverRadius: 5
            }
        },
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: chartStyle.color,
                },
                title: {
                    display: true,
                    text: text,
                    color: chartStyle.color,
                }
            },
            x: {
                ticks: {
                    color: chartStyle.color,
                }
            },
        },
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    color: chartStyle.color,
                    usePointStyle: true,
                    pointStyle: chartStyle.labels.pointStyle,
                    font: {
                        size: chartStyle.labels.size
                    }
                },
            },
            title: {
                display: true,
                text: title,
                color: chartStyle.color,
                font: {
                    size: chartStyle.titleSize
                }
            }
        }
    };
}

export function generateThresholdPlugin(step: number): Plugin {
    return {
        id: 'backgroundThresholds',
        beforeDraw: (chart) => {
            const {
                ctx,
                chartArea,
                scales: {
                    y
                }
            } = chart;

            let fromStep = 0;
            let i = 0;
            let toStep = step;

            while (fromStep < y.max) {
                ctx.save();
                ctx.fillStyle = chartStyle.thresholdColors[i];
                if (toStep < y.max && i < chartStyle.thresholdColors.length - 1) {
                    ctx.fillRect(chartArea.left, y.getPixelForValue(fromStep), chartArea.right - chartArea.left, y.getPixelForValue(toStep) - y.getPixelForValue(fromStep));
                } else {
                    ctx.fillRect(chartArea.left, y.getPixelForValue(fromStep), chartArea.right - chartArea.left, y.getPixelForValue(y.max) - y.getPixelForValue(fromStep));
                    break;
                }
                ctx.restore();

                fromStep = toStep;
                toStep += step;
                i++;
            }
        }
    }
}

export const plainBackgroundPlugin: Plugin = {
    id: 'plainBackground',
    beforeDraw: (chart, args, options) => {
        const {
            ctx,
            chartArea,
        } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || '#E1DEDD';
        ctx.fillRect(chartArea.left, chartArea.bottom, chartArea.right - chartArea.left, chartArea.top - chartArea.bottom);
        ctx.restore();
    }
};