import Grid from "@mui/material/Grid";
import { IncidentData, IncidentTypeData } from "../../../types/sms";
import {
    Chart as ChartJS,
    Tooltip, Legend,
    ChartData,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Title,
    BarElement,
    ChartConfiguration,
    Plugin
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { chartStyle, generateThresholdPlugin, getBarOptions, getLineOptions, plainBackgroundPlugin } from "../../../utils/chart";
import { useCallback, useEffect, useMemo } from "react";
import { IAnalyticsCharts } from "../KPICharts";

type ThresholdsMap = {
    [key in keyof IncidentData]?: number;
};

interface IIncidentCharts extends IAnalyticsCharts {
    data: IncidentData,
    timestamps: string[],
    thresholds: ThresholdsMap,
}

export default function IncidentCharts({
    timestamps,
    data,
    thresholds,
    companies,
    setCharts
}: Readonly<IIncidentCharts>) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const filterDataByCompany = useCallback((datasets: IncidentTypeData[]) => {
        if (companies.length > 0) {
            return datasets.filter((d) => {
                return companies.includes(d.companyName);
            });
        } else {
            return datasets;
        }
    }, [companies]);

    const formatLineData = useCallback((incident: IncidentTypeData[]) => {
        return {
            labels: timestamps,
            datasets: incident.map((d, i) => ({
                label: d.companyName,
                data: d.data,
                ...chartStyle.dataColors[i]
            })),
        }
    }, [timestamps]);

    // Create list of companies from data and then keep unique values in Set
    const dataCompanies = useMemo(() => Object.values(data).flatMap((incidentData: IncidentTypeData[]) => incidentData.map((d) => d.companyName)), [data]);
    const unfilteredCompanies = useMemo(() => new Set(dataCompanies), [dataCompanies]);

    // For performance reasons, only compute line and bar data on prop change
    const lineData = useMemo(() => Object.entries(data).reduce((prev, [type, d]) => {
        prev[type] = formatLineData(filterDataByCompany(d));
        return prev;
    }, {} as { [key: string]: ChartData<'line'> }), [data, formatLineData, filterDataByCompany]);

    const barData = useMemo(formatStackedBarData, [companies, data, unfilteredCompanies, filterDataByCompany]);

    // Set the chart data in Analytics Page for creating Report API request
    useEffect(() => {
        setCharts(
            [
                ...Object.entries(lineData).map(([type, d]) => {
                    const incidentType = type as keyof IncidentData;
                    return {
                        title: getIncidentTypeTitle(incidentType),
                        chart: {
                            type: 'line',
                            data: d
                        } as ChartConfiguration<"line">,
                        threshold: thresholds[incidentType],
                    }
                }),
                {
                    title: 'Incident Totals',
                    chart: {
                        type: 'bar',
                        data: barData,
                    }
                }
            ]
        );
    }, [setCharts, lineData, barData, thresholds]);

    function formatStackedBarData(): ChartData<'bar', number[]> {
        const companiesWithData = new Set<string>();
        const datasets = Object.entries(data)
            .map(([key, value], i) => {
                const filteredData = filterDataByCompany(value);

                filteredData.forEach((d) => companiesWithData.add(d.companyName));

                return {
                    label: getIncidentTypeTitle(key as keyof IncidentData),
                    data: filteredData
                        .map((companyData: IncidentTypeData) => companyData.data
                            .reduce((prev, cur) => prev + cur, 0)),
                    backgroundColor: chartStyle.dataColors[i].borderColor,
                }
            });

        // When company filter is provided, be sure to only display bars for companies that have data.
        // This ensures that we aren't displaying empty bars for "linked" providers/airlines.
        const labels = companies.length === 0 ?
            Array.from(unfilteredCompanies) :
            Array.from(companiesWithData);

        return {
            labels,
            datasets,
        }
    }

    function getIncidentTypeTitle(type: keyof IncidentData): string {
        switch (type) {
            case 'avop':
                return 'AVOP';
            case 'airside':
                return 'Airside';
            case 'environmental':
                return 'Environmental';
            case 'security':
                return 'Security';
        }
    }

    function getPlugins(type: keyof IncidentData): Plugin[] {
        const targetThreshold = thresholds[type];
        if (targetThreshold) {
            return [generateThresholdPlugin(targetThreshold)]
        };

        return [plainBackgroundPlugin];
    }

    return (
        <Grid container spacing={2}>
            {
                Object.entries(lineData).map(([type, d], i) => (
                    <Grid item xs={12} sm={6} key={`${type}-line`}>
                        <Card style={{ backgroundColor: '#1A2027' }}>
                            <CardContent>
                                <Line
                                    data={d}
                                    options={
                                        getLineOptions(
                                            getIncidentTypeTitle(type as keyof IncidentData),
                                            'Operational Performance Rating'
                                        )
                                    }
                                    plugins={getPlugins(type as keyof IncidentData)}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
            <Grid item xs={12} sm={6}>
                <Card style={{ backgroundColor: '#1A2027' }}>
                    <CardContent>
                        <Bar
                            data={formatStackedBarData()}
                            options={getBarOptions('Incident Totals')}
                            plugins={[plainBackgroundPlugin]}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )
}