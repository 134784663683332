// LEGACY
export interface AMS {
  id: string;
  description: string;
  company: string;
  type: Type;
  timestamp: string;
  severity: string | null;
  reason: string | null;
  recordType: AMSRecordType;
  updatedFlag?: boolean;
}

export interface AMSReturnType {
  companies: string[];
  data: {
    pending: AMS[];
    entered: AMS[];
    noFault: AMS[];
  };
}

export interface AMSPostType {
  uuid: string;
  severity: string | null;
  reason: string | null;
}

export type AMSRecordType = 'SMS' | 'BHS' | 'OTP';

export interface BaseIncident {
  id: string;
  date: string;
  company: string;
  points: number;
}

export interface Incident extends BaseIncident {
  type: string;
}

export enum BayType {
  PENDING = 'PENDING',
  ENTERED = 'ENTERED',
  NOFAULT = 'NOFAULT',
}

export enum Type {
  AA = 'Airside Accidents/Incidents/Occurrences/Near Misses',
  AVOP = 'AVOP Infractions',
  SECURITY = 'Security Breaches/Violations',
  ENVIRONMENTAL = 'Environmental',
  OTP = 'On-Time Performance',
  ABH = 'Arrival Baggage Handling',
};

export interface BayExtend {
  PENDING: boolean;
  ENTERED: boolean;
  NOFAULT: boolean;
}

export enum IncidentType {
  ALL = 'all',
  SECURITY = 'security',
  AVOP = 'AVOP',
  AIRSIDE = 'airside',
  ENVIRONMENTAL = 'environmental',
}

// NEW
export interface SmsAnalyticsResponse {
  timestamps: string[],
  airlineIncidents: IncidentData,
  groundHandlingIncidents: IncidentData,
}

export interface IncidentData {
  avop: IncidentTypeData[],
  security: IncidentTypeData[],
  airside: IncidentTypeData[],
  environmental: IncidentTypeData[]
};

export interface AnalyticsCommonResponse {
  timestamps: string[],
  datasets: IncidentTypeData[],
}

export interface IncidentTypeData {
  companyName: string,
  data: number[]
}