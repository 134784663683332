import Grid from "@mui/material/Grid";
import { AnalyticsCommonResponse } from "../../../types/sms";
import { Chart as ChartJS, Tooltip, Legend, ChartData, LinearScale, CategoryScale, PointElement, LineElement, Title, BarElement, ChartOptions } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    chartStyle,
    generateThresholdPlugin,
    getBarOptions,
    getLineOptions,
    plainBackgroundPlugin
} from "../../../utils/chart";
import { useEffect, useMemo } from "react";
import { IAnalyticsCharts } from ".";

interface IKpiCharts {
    title: string;
    lineThreshold?: number;
}

export default function KPICharts({
    title,
    timestamps,
    datasets,
    lineThreshold,
    companies,
    setCharts
}: Readonly<IKpiCharts & AnalyticsCommonResponse & IAnalyticsCharts>) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const unfilteredCompanies = useMemo(() => new Set(datasets.map((d) => d.companyName)), [datasets]);

    const filteredDatasets = useMemo(() => {
        if (companies.length > 0) {
            return datasets.filter((d) => companies.includes(d.companyName));
        } else {
            return datasets;
        }
    }, [companies, datasets]);

    const lineData = useMemo(formatLineData, [filteredDatasets, timestamps]);
    const lineChartTitle = `${title} Trends`;

    const barData = useMemo(formatBarData, [datasets, companies, unfilteredCompanies, filteredDatasets]);
    const barChartTitle = `${title} Totals`;

    // Set the chart data in Analytics Page for creating Report API request
    useEffect(() => {
        setCharts([
            {
                title: lineChartTitle,
                chart: {
                    type: 'line',
                    data: lineData,
                },
                threshold: lineThreshold,
            },
            {
                title: barChartTitle,
                chart: {
                    type: 'bar',
                    data: barData,
                }
            }
        ])
    }, [lineData, barData, lineThreshold, lineChartTitle, barChartTitle, setCharts])


    function formatLineData(): ChartData<'line', number[]> {
        return {
            labels: timestamps,
            datasets: filteredDatasets.map((d, i) => ({
                data: d.data,
                label: d.companyName,
                ...chartStyle.dataColors[i],
            })),
        }
    }

    function formatBarData(): ChartData<'bar', number[]> {
        const companiesWithData = datasets.map((d) => d.companyName);

        // When company filter is provided, be sure to only display bars for companies that have data.
        // This ensures that we aren't displaying empty bars for "linked" providers/airlines.
        const labels = companies.length === 0 ?
            Array.from(unfilteredCompanies) :
            companies.filter((company) => companiesWithData.includes(company));

        return {
            labels,
            datasets: [{
                label: 'Total',
                data: filteredDatasets.map((d) => d.data.reduce((prev, cur) => prev + cur, 0)),
                backgroundColor: chartStyle.dataColors[0].borderColor,
            }]
        };
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Card style={{ backgroundColor: '#1b1f24' }}>
                    <CardContent>
                        <Line
                            data={lineData}
                            options={
                                getLineOptions(
                                    lineChartTitle,
                                    'Operational Performance Rating'
                                )
                            }
                            plugins={[lineThreshold ? generateThresholdPlugin(lineThreshold) : plainBackgroundPlugin]}
                        />
                    </CardContent>
                </Card >
            </Grid >
            <Grid item xs={12} sm={6}>
                <Card style={{ backgroundColor: '#1b1f24' }}>
                    <CardContent>
                        <Bar
                            data={barData}
                            options={getBarOptions(barChartTitle, false)}
                            plugins={[plainBackgroundPlugin]}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )
}