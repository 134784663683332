import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { useEffect, useMemo } from "react";
import { useMapContext } from "../../context/Map";
import { TracksByTrackNumber } from "../../types/map";
import {
  createCircles,
  createCircleStyles,
  createLinks,
} from "../../utils/features";

interface ILinkedPointMarkers {
  tracksByTrackNumber?: TracksByTrackNumber;
}

export default function LinkedPointMarkers({
  tracksByTrackNumber,
}: ILinkedPointMarkers) {
  const map = useMapContext();

  const features = useMemo(() => {
    return Object.values(tracksByTrackNumber ?? {})?.reduce(
      (acc, tracksAtNumber) => {
        const linkedFeatures = createCircles(tracksAtNumber.tracks);
        const circleStyles = createCircleStyles(tracksAtNumber.color, "#000");
        linkedFeatures.forEach((feature) => feature.setStyle(circleStyles));

        const lineFeatures = createLinks(linkedFeatures, tracksAtNumber.color);

        acc.push(...linkedFeatures, ...lineFeatures);

        return acc;
      },
      [] as Feature<Geometry>[]
    );
  }, [tracksByTrackNumber]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const layer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
      layer?.dispose();
    };
  }, [features, map]);

  return null;
}
