import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { BaseField } from "./types";

interface IText extends BaseField {
  type?: "text" | "password";
}

export default function Text({
  control,
  defaultValue = "",
  disabled,
  fullWidth,
  id,
  label,
  rules,
  type = "text",
}: IText) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={id}
      render={({ field }) => (
        <TextField
          {...field}
          disabled={disabled}
          fullWidth={fullWidth}
          label={label}
          type={type}
        />
      )}
      rules={rules}
    />
  );
}
