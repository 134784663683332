import { styled} from "@mui/material";

export const FloatingLeftGroup = styled("div")`
  display: flex;
  left: 8px;
  position: absolute;
  top: 8px;
  z-index: 1299;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const FloatingRightGroup = styled("div")`
  position: absolute;
  right: 8px;
  top: 8px;

  > button:not(:last-child) {
    margin-right: 16px;
  }
`;

export const FloatingBottomRightGroup = styled("div")`
  position: absolute;
  left: 8px;
  bottom: 8px;
  opacity: 60%;
  pointer-events: none;
`;