import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Control, useWatch } from "react-hook-form";
import { Select } from "../../components/form";
import { TargetsAtSourceTimestamp } from "../../types/map";
import { createOptionsByKey, filterTracks } from "../../utils/filters";
import { FilterForm } from "./types";

interface IStaticFilters {
  control: Control<FilterForm>;
  tracks: TargetsAtSourceTimestamp[] | null;
}

export default function StaticFilters({ control, tracks }: IStaticFilters) {
  const airline = useWatch({ control, name: "airline" });

  const airlineOptions = useMemo(() => {
    return createOptionsByKey(tracks, "Airline");
  }, [tracks]);

  const aircraftTypeOptions = useMemo(() => {
    const filteredTracks = filterTracks(tracks, {
      Airline: airline,
    });
    return createOptionsByKey(filteredTracks, "AircraftType");
  }, [airline, tracks]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Select
          control={control}
          fullWidth
          id="airline"
          label="Airline"
          multiple
          options={airlineOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          control={control}
          fullWidth
          id="aircraftType"
          label="Aircraft Type"
          multiple
          options={aircraftTypeOptions}
        />
      </Grid>
    </Grid>
  );
}
