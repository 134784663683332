import VectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source.js";
import { useEffect, useMemo } from "react";
import { useAuthContext } from "../../context/Auth";
import { useMapContext } from "../../context/Map";
import { Track } from "../../types/map";
import { createPlaybackMarker } from "../../utils/features";

interface IPlaybackMarker {
  tracks?: Track[];
}

export default function PlaybackMarkers({ tracks }: IPlaybackMarker) {
  const { config } = useAuthContext();
  const map = useMapContext();

  const features = useMemo(() => {
    return tracks?.map(
      // TODO: get airport code from config when available
      (track) => createPlaybackMarker(track, config.airport)
    );
  }, [tracks, config]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const layer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
      // Necessary to maintain features during interactions while playback is running.
      // May cause performance issues.
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
      layer?.dispose();
    };
  }, [features, map]);

  return null;
}
