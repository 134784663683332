import { useState } from "react";
import { Paper, styled, IconButton } from "@mui/material";
import Replay from "@mui/icons-material/Replay";
import LiveTv from "@mui/icons-material/LiveTv";
import Thermostat from "@mui/icons-material/Thermostat";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Dashboard from "@mui/icons-material/Dashboard"
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Logo from "../../assets/logo_small.svg";
import SidebarNavButton from "./SidebarNavButton";
import SidebarProfileButton from "./SidebarProfileButton";
import { useAuthContext } from "../../context/Auth";

const StyledSidebar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{
  expanded: boolean;
}>(({ expanded }) => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  maxWidth: expanded ? "180px" : "58px",
  paddingTop: "8px",
  textAlign: "center",
  width: expanded ? "180px" : "58px",
  position: "relative",
  "& img": {
    width: expanded ? "100px" : "47px",
    height: expanded ? "100px" : "47px",

    display: "inline-block",
    borderRadius: "60px",
    boxShadow: "0 0 8px #888",
    padding: "0.5em 0.6em",

    margin: "5px",
  },
  "& ul": {
    listStyleType: "none",
    marginBlockStart: "32px",
    marginBlockEnd: "32px",
    paddingInlineStart: "0",
  },
  "& li:not(:last-child)": {
    paddingBottom: "8px",
  },
}));

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 45%;
  right: 0;
  transform: translateY(-45%);
`;

const SidebarContentWrapper = styled("div")`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export default function Sidebar() {
  const [expanded, setExpanded] = useState(false);
  const { config } = useAuthContext();

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledSidebar as="nav" elevation={1} square={true} expanded={expanded}>
      <div>
        <img alt="Searidge logo" src={Logo} />
      </div>
      <SidebarContentWrapper>
        <ul>
          {config.module?.playback && <li>
            <SidebarNavButton
              icon={<Replay />}
              label="Playback"
              to="/playback"
              expanded={expanded}
            />
          </li>}
          {config.module?.live && <li>
            <SidebarNavButton
              icon={<LiveTv />}
              label="Live"
              to="/live"
              expanded={expanded}
            />
          </li>}
          {config.module?.staticAnalysis && <li>
            <SidebarNavButton
              icon={<Thermostat />}
              label="Static Analysis"
              to="/static-analysis"
              expanded={expanded}
            />
          </li>}
          {config.module?.dashboard && <li>
            <SidebarNavButton
              icon={<Dashboard />}
              label="Analytics"
              to="/analytics"
              expanded={expanded}
            />
          </li>}
          {config.module?.sms && <li>
            <SidebarNavButton
              icon={<ReportProblemIcon />}
              label="AMS Dashboard"
              to="/ams"
              expanded={expanded}
            />
          </li>}
        </ul>
        <StyledIconButton onClick={handleExpandCollapse}>
          {expanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </StyledIconButton>
        <SidebarProfileButton expanded={expanded} />
      </SidebarContentWrapper>
    </StyledSidebar>
  );
}
