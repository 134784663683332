import {
  TracksAtTimestamp,
  TargetsAtSourceTimestamp,
  Track,
  TrackRow,
  TracksByTrackNumber,
} from "../types/map";
import { filterUniqueAt } from "./array";
import { getRandomColor } from "./colors";

/**
 * Finds the unique tracks for a given timestamp threshold.
 *
 * @param tracks Tracks to filter.
 * @param timestamp Timestamp to match, based on threshold.
 * @param threshold Threshold to divide the raw timestamps
 * @returns Unique tracks.
 */
function getLastUniqueTracks(
  tracks: TargetsAtSourceTimestamp[],
  timestamp: number,
  threshold: number
) {
  return tracks
    .filter(
      (track) => Math.trunc(track.SourceTimestamp / threshold) === timestamp
    )
    .reduce((acc, track) => {
      acc.push(...track.Targets);
      return acc;
    }, [] as Track[])
    .reverse()
    .filter(filterUniqueAt("TrackNumber"));
}

/**
 * Finds the tracks from the previous interval that are missing in the current interval.
 *
 * @param tracks Unique tracks from current interval.
 * @param prevTracks Unique tracks from previous interval.
 * @returns Tracks from previous interval missing in current.
 */
function getTracksToPersist(tracks: Track[], prevTracks: Track[]) {
  return prevTracks.filter(
    (prevTarget) =>
      !tracks.some(
        (currentTarget) => currentTarget.TrackNumber === prevTarget.TrackNumber
      )
  );
}

/**
 * Creates an array of unique tracks per timestamp interval based on a millisecond threshold.
 *
 * @param tracks Raw track data.
 * @param startTime Start time.
 * @param endTime End time.
 * @param threshold Millisecond interval per playback frame.
 * @returns Unique tracks per timestamp
 */
export function transformPlaybackTracks(
  tracks: TargetsAtSourceTimestamp[],
  startTime?: Date | null,
  endTime?: Date | null,
  threshold: number = 500
): TracksAtTimestamp[] {
  if (!tracks?.length || !startTime || !endTime) {
    return [];
  }

  const start = Math.trunc(startTime.valueOf() / threshold);
  const end = Math.trunc(endTime.valueOf() / threshold);
  const intervals = end - start;

  const tracksByInterval = [] as TracksAtTimestamp[];

  // Ensure all timestamp intervals are represented.
  for (let i = 0; i < intervals; i++) {
    const timestampInterval = start + i;

    const tracksAtInterval = getLastUniqueTracks(
      tracks,
      timestampInterval,
      threshold
    );

    // Persist tracks from previous intervals for consistent playback.
    // Show last-known coordinates.
    if (i > 0) {
      const tracksToPersist = getTracksToPersist(
        tracksAtInterval,
        tracksByInterval[i - 1].tracks
      );
      tracksAtInterval.push(...tracksToPersist);
    }

    tracksByInterval.push({
      timestamp: timestampInterval * threshold, // restore to milliseconds
      tracks: tracksAtInterval,
    });
  }

  return tracksByInterval;
}

export function transformStaticAnalysisTracks(
  tracksBySourceTimestamp: TargetsAtSourceTimestamp[]
) {
  return tracksBySourceTimestamp.reduce((acc, { SourceTimestamp, Targets }) => {
    Targets.forEach((track) => {
      const trackWithTimestamp = { ...track, SourceTimestamp };
      if (acc?.[track.TrackNumber]) {
        acc[track.TrackNumber].tracks.push(trackWithTimestamp);
      } else {
        acc[track.TrackNumber] = {
          color: getRandomColor(),
          tracks: [trackWithTimestamp],
        };
      }
    });
    return acc;
  }, {} as TracksByTrackNumber);
}

function sortTrackRows(tracks: TrackRow[]) {
  return tracks.sort((a, b) =>
    // compare airplanes by callsign
    a?.Callsign && b?.Callsign && a.Callsign !== b.Callsign
      ? a.Callsign.localeCompare(b.Callsign)
      : // unknown < airplane
      !a?.Callsign && !!b?.Callsign
      ? 1
      : // airplane > unknown
      !!a?.Callsign && !b?.Callsign
      ? -1
      : // compare unknowns by track number
        a.TrackNumber - b.TrackNumber
  );
}

export function transformTableRowTracksByTimestamp(
  tracksByTimestamp: TracksAtTimestamp[]
) {
  const tracks = tracksByTimestamp.reduce((acc, tracksAtTimestamp) => {
    tracksAtTimestamp.tracks.forEach((track) => {
      const exists = acc.find(
        (accTrack) => accTrack.TrackNumber === track.TrackNumber
      );

      if (!exists) {
        acc.push({
          AircraftType: track?.AircraftType,
          Callsign: track?.Callsign,
          TrackNumber: track.TrackNumber,
        });
      }
    });

    return acc;
  }, [] as TrackRow[]);

  return sortTrackRows(tracks);
}

export function transformTableRowTracksByNumber(
  tracksByNumber: TracksByTrackNumber
): TrackRow[] {
  const tracks = Object.values(tracksByNumber).map(({ tracks }) => ({
    AircraftType: tracks[0]?.AircraftType,
    Callsign: tracks[0]?.Callsign,
    TrackNumber: tracks[0]?.TrackNumber,
  }));

  return sortTrackRows(tracks);
}
