import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { DateTimeRangeForm } from "../components/form/types";
import { TargetsAtSourceTimestamp } from "../types/map";
import { useAuthContext } from "../context/Auth";

interface TracksData {
  clearErrorMsg: () => void;
  errorMsg: string | null;
  form: UseFormReturn<DateTimeRangeForm>;
  fetchTracks: (startTime: Date, endTime: Date) => Promise<void>;
  resetForm: () => void;
  tracks: TargetsAtSourceTimestamp[] | null;
}

const defaultValues = {
  end: null,
  start: null,
};

export default function useTracks(): TracksData {
  const [tracks, setTracks] = useState<TargetsAtSourceTimestamp[]>([]);
  const [loadedRange, setLoadedRange] =
    useState<DateTimeRangeForm>(defaultValues);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const form = useForm<DateTimeRangeForm>({ defaultValues, mode: "onSubmit" });
  const { socket } = useAuthContext();

  const { reset } = form;

  const fetchTracks = useCallback(
    async (start: Date, end: Date) => {
      socket.emit('playback:stream', start, end);
      setLoadedRange({ start, end });
      reset({ start, end });
    }, [reset]
  );

  function onDataFetched(newTracks: TargetsAtSourceTimestamp[]) {
    setTracks(previous => previous.concat(...newTracks));
  }

  useEffect(() => {
    function onError(errMsg: string) {
      return setErrorMsg(errMsg);
    }

    socket.on('playback:error', onError);
    socket.on('playback:data', onDataFetched);

    return () => {
      socket.off('playback:error', onError);
      socket.off('playback:data', onDataFetched);
    };
  }, [])

  const resetForm = useCallback(() => {
    reset(loadedRange);
    setErrorMsg(null);
  }, [loadedRange, reset]);

  const clearErrorMsg = useCallback(() => setErrorMsg(null), []);

  return {
    clearErrorMsg,
    errorMsg,
    form,
    fetchTracks,
    resetForm,
    tracks,
  };
}
