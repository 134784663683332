/**
 * Filter unique entries by using the specified attribute.
 *
 * @param key Attribute key.
 * @returns Unique entries.
 */
export function filterUniqueAt(key: string) {
  return (value: any, index: number, array: any[]) => {
    const firstIndex = array.findIndex(
      (r) =>
        r?.[key] !== undefined &&
        value?.[key] !== undefined &&
        r?.[key] === value?.[key]
    );
    return firstIndex === index;
  };
}

export function stringSort(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}
