import { FormControl, InputLabel, MenuItem } from "@mui/material";
import MUISelect from "@mui/material/Select";
import { useEffect } from "react";
import { Controller, useController } from "react-hook-form";
import { BaseField } from "./types";

interface IOption {
  id: string;
  value: string;
}

interface ISelect extends BaseField {
  multiple?: boolean;
  options: IOption[];
  size?: 'medium' | 'small';
}

export default function Select({
  control,
  defaultValue = "",
  disabled,
  fullWidth,
  id,
  label,
  multiple,
  options,
  rules,
  size = 'medium'
}: ISelect) {
  const { field } = useController({ control, name: id });

  /**
   * Blurs the component when closing the menu. Does not function without the brief delay from setTimeout.
   */
  function handleClose() {
    setTimeout(() => (document?.activeElement as HTMLDivElement)?.blur(), 0);
  }

  // Clear values that are no longer valid options.
  useEffect(() => {
    const optionValues = options.map((option) => option.value);

    if (
      multiple &&
      (field.value as string[]).some((value) => !optionValues.includes(value))
    ) {
      const nextValues = (field.value as string[]).filter((value) =>
        optionValues.includes(value)
      );
      field.onChange(nextValues);
    }

    if (!multiple && !optionValues.includes(field.value)) {
      field.onChange("");
    }
  }, [field, field.value, multiple, options]);

  return (
    <Controller
      control={control}
      defaultValue={
        multiple && !Array.isArray(defaultValue) ? [defaultValue] : defaultValue
      }
      name={id}
      render={({ field }) => (
        <FormControl disabled={disabled} fullWidth={fullWidth} size={size}>
          {label && <InputLabel>{label}</InputLabel>}
          <MUISelect
            {...field}
            label={label}
            multiple={multiple}
            onClose={handleClose}
          >
            {!!options?.length &&
              options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
          </MUISelect>
        </FormControl>
      )}
      rules={rules}
    />
  );
}
