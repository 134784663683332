export interface FilterOption {
    id: TimeFormat;
    value: string;
}

export enum TimeFormat {
    ALL = 'all',
    YEARLY = 'yearly',
    MONTHLY = 'monthly',
    BIMONTHLY = 'bimonthly',
    DAILY = 'daily',
    HOURLY = 'hourly'
};

export type ChartFilterId = 'airline' | 'aircraftType' | 'alarmTypeID'
export type ChartFilterLabel = 'Airline' | 'Aircraft Type' | 'Alarm Type ID'