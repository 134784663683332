import { TrackTableColumn } from "../../types/map";

const columns = [
  {
    align: "center",
    key: "TrackNumber",
    label: "Track #",
  },
  {
    align: "center",
    key: "Callsign",
    label: "Callsign",
  },
  {
    align: "center",
    key: "AircraftType",
    label: "Aircraft Type",
  },
] as TrackTableColumn[];

export default columns;
