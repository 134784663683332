import { SetStateAction, useState } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { ControlCard, PageContainer } from '../../components/styled';
import { DateTimeRangePicker } from '../../components/form';
import { DateTimeRangeForm } from '../../components/form/types';
import { TimeFormat } from '../../types/chart';
import { useForm } from "react-hook-form";

import { useAuthContext } from "../../context/Auth";
import ReportAccordion from '../../components/navigation/ReportAccordion/ReportAccordion';
import { downloadReportPdf, getKpiBhsData, getKpiOtpData, getKpiSmsData } from '../../api';
import { IncidentData, AnalyticsCommonResponse, SmsAnalyticsResponse, IncidentTypeData } from '../../types/sms';
import KPICharts from './KPICharts';
import { ReportSection } from '../../types/report';
import { Access } from '../../types/api';
import Toast from '../../components/Toast';
import IncidentCharts from './IncidentCharts';
import { CompaniesFilter } from './CompaniesFilter';
import { FilterOption } from "../../types/chart";

const current = new Date();
const defaultEndDate = new Date(new Date().setMonth(current.getMonth() - 6));
const defaultStartDate = new Date(new Date().setFullYear(current.getFullYear() - 1));
const emptyIncidentData: IncidentData = {
  avop: [],
  security: [],
  airside: [],
  environmental: []
}

export default function AnalyticsPage() {
  const { access } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const [smsAnalyticsData, setSmsAnalyticsData] = useState<SmsAnalyticsResponse>({ timestamps: [], airlineIncidents: emptyIncidentData, groundHandlingIncidents: emptyIncidentData });
  const [otpAnalyticsData, setOtpAnalyticsData] = useState<AnalyticsCommonResponse>({ timestamps: [], datasets: [] });
  const [bhsAnalyticsData, setBhsAnalyticsData] = useState<AnalyticsCommonResponse>({ timestamps: [], datasets: [] });
  const [chartSections, setChartSections] = useState<Map<string, ReportSection>>(new Map());
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [companies, setCompanies] = useState<string[]>([]);

  const defaultValues: DateTimeRangeForm = {
    end: defaultEndDate,
    start: defaultStartDate,
    timeFormat: TimeFormat.BIMONTHLY,
    download: false,
  };

  const form = useForm<DateTimeRangeForm>({ defaultValues, mode: "onSubmit" });

  async function fetchData(timeStartEpoch: number, timeEndEpoch: number, timeFormat: TimeFormat) {
    setSmsAnalyticsData(await getKpiSmsData(timeStartEpoch, timeEndEpoch, timeFormat))
    setOtpAnalyticsData(await getKpiOtpData(timeStartEpoch, timeEndEpoch, timeFormat))
    setBhsAnalyticsData(await getKpiBhsData(timeStartEpoch, timeEndEpoch, timeFormat))
  }

  async function onSubmit({ start, end, timeFormat, download }: DateTimeRangeForm) {
    if (!start || !end || !timeFormat) {
      return;
    } else if (download) {
      await downloadReport(start, end);
    } else {
      setIsLoading(true);
      setCanDownload(false);

      // Reset data to reset the accordion state before next render
      setSmsAnalyticsData({ timestamps: [], airlineIncidents: emptyIncidentData, groundHandlingIncidents: emptyIncidentData });
      setOtpAnalyticsData({ timestamps: [], datasets: [] });
      setBhsAnalyticsData({ timestamps: [], datasets: [] });

      await fetchData(start.valueOf(), end.valueOf(), timeFormat)
        .catch((_) => {
          setErrorMsg('Something went wrong. Please try again.');
        });

      setIsLoading(false);
      setCanDownload(true);
    }
  }

  async function downloadReport(startTime: Date, endTime: Date) {
    setIsDownloading(true);
    try {
      await downloadReportPdf({
        title: 'KPI Incidents Report',
        description: 'A SRT Report',
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        sections: [...chartSections.values()],
      });
    } catch (err) {
      setErrorMsg('Something went wrong. Please try again.');
    };
    setIsDownloading(false);
  }

  function getSectionHeader(section: keyof Access): string {
    switch (section) {
      case 'AI':
        return 'Terminal Service Provider Incidents';
      case 'GHI':
        return 'Ground Handler Incidents';
      case 'OTP':
        return 'On Time Performance';
      default:
        return 'Arrival Baggage Handling';
    }
  }

  function getSectionSummary(section: keyof Access): string {
    switch (section) {
      case 'AI':
        return 'Set of charts and graphs that provide insights into the reported terminal service provider incidents at the airport.';
      case 'GHI':
        return 'Set of charts and graphs that provide insights into the reported ground handling incidents at the airport.';
      case 'OTP':
        return 'Sets of KPIs that provide useful insights into the overall on time performance of the airlines at the airport.';
      default:
        return 'Sets of KPIs that provide useful insights into the overall performance of the baggage handling providers at the airport.';
    }
  }

  function formatChartSection(section: keyof Access, charts: ReportSection['charts']): ReportSection {
    return {
      title: getSectionHeader(section),
      description: getSectionSummary(section),
      charts,
    }
  }

  function handleSetChart(section: keyof Access, charts: ReportSection['charts']) {
    setChartSections((prev) => prev.set(getSectionHeader(section), formatChartSection(section, charts)));
  }

  function flattenIncidentData(incidentData: IncidentData): IncidentTypeData[] {
    return [
      ...incidentData.avop,
      ...incidentData.airside,
      ...incidentData.environmental,
      ...incidentData.security
    ]
  }

  // Only monthly and bi-monthly dates are supported by Analytics API, so make those only viable options
  const getTimeFormatOptions = (start: Date, end: Date): FilterOption[] => {
    const options: FilterOption[] = [];
  
    if (end > start) {  
      return [{ id: TimeFormat.MONTHLY, value: 'Monthly' }, { id: TimeFormat.BIMONTHLY, value: 'BiMonthly' }];
    } else {
      console.error('End date must bigger than Start date!');
    }
    return options;
  }

  return (
    <PageContainer sx={{ overflow: 'hidden' }}>
      <ControlCard>
        <Grid
          alignItems='flex-start'
          container
          justifyContent='space-between'
          spacing={3}
          rowSpacing={3}
        >
          <Grid item xs={12} lg={3} justifyContent='left'>
            <Typography
              variant='h4'
              color='textSecondary'
              padding='2px 10px'>
              Analytics
            </Typography>
          </Grid>
          <Grid container justifyContent={'right'} flexDirection={'row'} alignItems={'center'} marginRight={4}>
            <Grid item>
              <CompaniesFilter
                data={[
                  ...flattenIncidentData(smsAnalyticsData.airlineIncidents),
                  ...flattenIncidentData(smsAnalyticsData.groundHandlingIncidents),
                  ...bhsAnalyticsData.datasets,
                  ...otpAnalyticsData.datasets
                ]}
                updateCompaniesFilter={setCompanies}
              />
            </Grid>
            <Grid item justifyContent='right'>
              <DateTimeRangePicker
                form={form}
                maxRangeInMinutes={5256000}
                onSubmit={onSubmit}
                enableTimeFormat={true}
                isLoading={isLoading}
                isDownloading={isDownloading}
                canDownload={canDownload}
                getTimeFormatOpts={getTimeFormatOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </ControlCard>

      <Typography component='div' sx={{ overflowY: 'auto' }}>
        <Stack spacing={1}>
          {access.AI &&
            <ReportAccordion
              header={getSectionHeader('AI')}
              summary={getSectionSummary('AI')}
              initExpanded={!!smsAnalyticsData.timestamps.length}
              disabled={!smsAnalyticsData.timestamps.length}
            >
              <IncidentCharts
                data={smsAnalyticsData.airlineIncidents}
                timestamps={smsAnalyticsData.timestamps}
                setCharts={(charts) => handleSetChart('AI', charts)}
                thresholds={{
                  security: 3.2,
                  avop: 1.6,
                  airside: 3.9,
                }}
                companies={companies}
              />
            </ReportAccordion>}
          {access.GHI &&
            <ReportAccordion
              header={getSectionHeader('GHI')}
              summary={getSectionSummary('GHI')}
              initExpanded={!!smsAnalyticsData.timestamps.length}
              disabled={!smsAnalyticsData.timestamps.length}
            >
              <IncidentCharts
                data={smsAnalyticsData.groundHandlingIncidents}
                timestamps={smsAnalyticsData.timestamps}
                setCharts={(charts) => handleSetChart('GHI', charts)}
                thresholds={{
                  security: 8,
                  avop: 6.2,
                  airside: 3.2,
                }}
                companies={companies}
              />
            </ReportAccordion>}
          {access.OTP &&
            <ReportAccordion
              header={getSectionHeader('OTP')}
              summary={getSectionSummary('OTP')}
              initExpanded={!!otpAnalyticsData.timestamps.length}
              disabled={!otpAnalyticsData.timestamps.length}
            >
              <KPICharts
                title={getSectionHeader('OTP')}
                datasets={otpAnalyticsData.datasets}
                timestamps={otpAnalyticsData.timestamps}
                setCharts={(charts) => handleSetChart('OTP', charts)}
                companies={companies}
              />
            </ReportAccordion>}
          {access.BHS &&
            <ReportAccordion
              header={getSectionHeader('BHS')}
              summary={getSectionSummary('BHS')}
              initExpanded={!!bhsAnalyticsData.timestamps.length}
              disabled={!bhsAnalyticsData.timestamps.length}
            >
              <KPICharts
                title={getSectionHeader('BHS')}
                datasets={bhsAnalyticsData.datasets}
                timestamps={bhsAnalyticsData.timestamps}
                lineThreshold={164.4}
                setCharts={(charts) => handleSetChart('BHS', charts)}
                companies={companies}
              />
            </ReportAccordion>}
        </Stack>
      </Typography>
      <Toast message={errorMsg} onClose={() => setErrorMsg(null)} />
    </PageContainer>
  );
}