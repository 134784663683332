import { createTheme, lighten } from "@mui/material";
// Allows for overriding of the x-date-pickers components
import type {} from "@mui/x-date-pickers/themeAugmentation";

declare module '@mui/material/styles' {
  interface Palette {
    third: Palette['primary'];
  }

  interface PaletteOptions {
    third?: PaletteOptions['primary'];
  }
}

export const colors = {
  // palette
  sunray: "#fcb93c",
  sunset: "#f27044",
  rosewood: "#bf3c51",
  plum: "#812a56",
  runway: "#635d5a",
  taxiway: "#9d948e",
  // markers
  arriving: "#7ec6e2",
  departing: "#e1d17b",
  unknown: "#a5a5a5",
  // components
  paperBackground: "#121212",
  scrollbarBackground: "#a7a7a7",
  dashboardScrollbarBackground: "#444444",
};

export const gradients = {
  paper:
    "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  picker:
    "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
};

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: 2,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 5,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&:disabled": {
            backgroundImage: gradients.picker,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "::-webkit-scrollbar": {
            width: 10,
            backgroundColor: colors.paperBackground,
            backgroundImage: gradients.paper,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-button": {
            backgroundColor: colors.scrollbarBackground,
            height: 0,
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: colors.paperBackground,
            backgroundImage: gradients.paper,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: colors.dashboardScrollbarBackground,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: lighten(colors.dashboardScrollbarBackground, 0.2),
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "::-webkit-scrollbar": {
            width: 10,
            backgroundColor: colors.paperBackground,
            backgroundImage: gradients.picker,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-button": {
            backgroundColor: colors.scrollbarBackground,
            height: 0,
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: colors.paperBackground,
            backgroundImage: gradients.picker,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: colors.dashboardScrollbarBackground,
            borderRadius: "8px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: lighten(colors.dashboardScrollbarBackground, 0.1),
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: colors.rosewood,
    },
    secondary: {
      main: colors.plum,
    },
    info: {
      main: colors.scrollbarBackground,
    }
  },
});

export const chartStyle = {
  color: '#FFFFFF',
  titleSize: 16,
  labels: {
    size: 12,
    pointStyle: 'circle'
  },
  plugins: {
    position: 'right'
  },
  colors: [
    ['rgba(255, 188, 43, 0.5)', 'rgba(255, 188, 43, 0.9)'],
    ['rgba(214, 255, 153, 0.5)', 'rgba(214, 255, 153, 0.9)'],
    ['rgba(31, 210, 255, 0.5)', 'rgba(31, 210, 255, 0.9)'],
    ['rgba(168, 196, 255, 0.5)', 'rgba(168, 196, 255, 0.9)'],
    ['rgba(255, 171, 181, 0.5)', 'rgba(255, 171, 181, 0.9)'],
    ['rgba(151, 33, 255, 0.5)', 'rgba(151, 33, 255, 0.9)'],
    ['rgba(82, 255, 191, 0.5)', 'rgba(82, 255, 191, 0.9)'],
    ['rgba(255, 136, 38, 0.5)', 'rgba(255, 136, 38, 0.9)'],
    ['rgba(255, 0, 200, 0.5)', 'rgba(255, 0, 200, 0.9)'],
    ['rgba(0, 242, 255, 0.5)', 'rgba(0, 242, 255, 0.9)'],
    ['rgba(255, 214, 181, 0.5)', 'rgba(255, 214, 181, 0.9)'],
    ['rgba(255, 15, 75, 0.5)', 'rgba(255, 15, 75, 0.9)'],
    ['rgba(255, 106, 56, 0.5)', 'rgba(255, 106, 56, 0.9)'],
    ['rgba(8, 185, 255, 0.5)', 'rgba(8, 185, 255, 0.9)'],
    ['rgba(255, 233, 176, 0.5)', 'rgba(255, 233, 176, 0.9)'],
    ['rgba(255, 126, 33, 0.5)', 'rgba(255, 126, 33, 0.9)'],
    ['rgba(255, 0, 30, 0.5)', 'rgba(255, 0, 30, 0.9)'],
    ['rgba(255, 221, 0, 0.5)', 'rgba(255, 221, 0, 0.9)'],
    ['rgba(255, 0, 140, 0.5)', 'rgba(255, 0, 140, 0.9)'],
    ['rgba(75, 95, 249, 1)', 'rgba(75, 95, 249, 0.9)'],
  ],
}

export default theme;

