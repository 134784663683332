import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../api";
import { Config, Access } from "../types/api";
import { User, UserLogin } from "../types/user";
import { io, Socket } from "socket.io-client";

interface IAuthContext {
  authenticated: boolean;
  access: Access;
  config: Config;
  socket: Socket;
  login: (data: UserLogin) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
}

interface IAuthProvider {
  children?: ReactNode;
}
const initialAccess: Access = { AI: false, GHI: false, OTP: false, BHS: false };

const { NODE_ENV, REACT_APP_API_URL } = process.env;
const socket = io(
  REACT_APP_API_URL ?? '',
  {
    path: NODE_ENV === 'production' ? undefined : '/dashboard-socket',
    autoConnect: false,
    withCredentials: true,
    secure: true,
  }
);

export function useAuth(): IAuthContext {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [config, setConfig] = useState<Config>({});
  const idleTimeout = useRef<NodeJS.Timeout | null>(null);
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [hasValidToken, setHasValidToken] = useState<boolean>(
    !!sessionStorage.getItem("srt--user")
  );
  const [access, setAccess] = useState<Access>(initialAccess);

  const clearSession = useCallback(
    async (shouldLogout = true) => {
      if (idleTimeout?.current) {
        clearTimeout(idleTimeout?.current);
      }

      if (refreshTimeout?.current) {
        clearTimeout(refreshTimeout.current);
      }
      setHasValidToken(false);
      sessionStorage.removeItem("srt--user");
      sessionStorage.removeItem("srt--config");

      setUser(null);

      socket.auth = {};
      socket.disconnect();

      navigate("/login");
      if (shouldLogout) {
        await api.logout();
      }
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    try {
      await api.logout();
    } catch (error) {
      // consume until contingency provided; unable to manually clear httpOnly cookies from frontend
    } finally {
      clearSession(false); // set false to prevent calling /logout again
    }
  }, [clearSession]);

  const setIdleTimeout = useCallback(
    (idleTimeInSec?: number) => {
      if (idleTimeout?.current) {
        clearTimeout(idleTimeout.current);
      }

      if (idleTimeInSec) {
        const timeout = setTimeout(logout, idleTimeInSec * 1000);
        idleTimeout.current = timeout;
      }
    },
    [logout]
  );

  const setRefreshTimeout = useCallback((idleTimeInSec?: number) => {
    if (refreshTimeout?.current) {
      clearTimeout(refreshTimeout.current);
    }

    if (idleTimeInSec) {
      const timeout = setTimeout(
        () => setShouldRefresh(true),
        (idleTimeInSec - 5) * 1000
      );
      refreshTimeout.current = timeout;
    }
  }, []);

  const refresh = useCallback(
    async (setTimeout = true) => {
      await api.refresh();
      if (setTimeout) {
        setRefreshTimeout(user?.idleTimeInSec);
      }
    },
    [setRefreshTimeout, user?.idleTimeInSec]
  );

  const login = useCallback(
    async (data: UserLogin) => {
      const { user: _user, config: _config } = await api.login(data);
      setAccess(_user.access);
      setUser(_user);
      setConfig(_config);
      setIdleTimeout(_user?.idleTimeInSec);
      setRefreshTimeout(_user?.idleTimeInSec);
      setHasValidToken(true);
      socket.auth = _user;
    },
    [setIdleTimeout, setRefreshTimeout]
  );

  useEffect(() => {
    // this will run everytime the page is refreshed/ mounted.
    // call and api endpoint to check if the token is still valid and maintain the user session state.

    api.getMap().then((data) => {
      if (data) {
        setHasValidToken(true);
        // call the /refresh token endpoint on page reload
        refresh(false); // turn off set refresh timeout
      } else {
        setHasValidToken(false);
        sessionStorage.removeItem("srt--user");
        sessionStorage.removeItem("srt--config");
      }
    }).catch((error) => {
      setHasValidToken(false);
      sessionStorage.removeItem("srt--user");
      sessionStorage.removeItem("srt--config");
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // this will run everytime the page is refreshed/ mounted.
    // fetch user state from sessionStorage. trigger idle and refresh timeout listeners
    const storedUser = sessionStorage.getItem("srt--user");
    const storedConfig = sessionStorage.getItem("srt--config");
    if (storedUser) {
      const user: User = JSON.parse(storedUser);
      setUser(user);
      setAccess(user.access);
      setIdleTimeout(user.idleTimeInSec);
      setRefreshTimeout(user.idleTimeInSec);
    }
    if (storedConfig) {
      setConfig(JSON.parse(storedConfig));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update session state every time the user value changes
    if (user) {
      sessionStorage.setItem("srt--user", JSON.stringify(user));
    } else {
      sessionStorage.removeItem("srt--user");
    }
  }, [user, config]);

  useEffect(() => {
    // update session state every time the config value changes
    if (Object.keys(config).length > 0) {
      sessionStorage.setItem("srt--config", JSON.stringify(config));
    } else {
      sessionStorage.removeItem("srt--config");
    }
  }, [config]);

  useEffect(() => {
    const listener = () => setIdleTimeout(user?.idleTimeInSec);

    document.addEventListener("click", listener);
    document.addEventListener("keypress", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("keypress", listener);
    };
  }, [setIdleTimeout, user?.idleTimeInSec]);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      refresh();
    }
  }, [refresh, shouldRefresh]);

  api.useInterceptors(clearSession);

  return {
    authenticated: hasValidToken || !!user,
    access,
    config,
    socket,
    login,
    logout,
    refresh,
  };
}

const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  access: initialAccess,
  config: {},
  socket: socket,
  login: async () => { },
  logout: async () => { },
  refresh: async () => { },
});

export function AuthProvider({ children }: IAuthProvider) {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext<IAuthContext>(AuthContext);
