import { Feature } from "ol";
import { Point } from "ol/geom";
import { Heatmap } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { useEffect, useMemo } from "react";
import { useMapContext } from "../../context/Map";
import { TracksByTrackNumber } from "../../types/map";

interface IHeatmapLayer {
  tracksByTrackNumber?: TracksByTrackNumber;
}

export default function HeatmapLayer({ tracksByTrackNumber }: IHeatmapLayer) {
  const map = useMapContext();

  const features = useMemo(() => {
    return Object.values(tracksByTrackNumber ?? {})?.reduce(
      (acc, { tracks }) => {
        const nextFeatures = tracks.map(
          (track) =>
            new Feature({
              geometry: new Point([...track?.WGS84Position].reverse()),
              // weight: [0-1]
            })
        );
        return acc.concat(nextFeatures);
      },
      [] as Feature<Point>[]
    );
  }, [tracksByTrackNumber]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const heatmap = new Heatmap({
      source: new VectorSource({ features }),
      blur: 30,
      radius: 5,
      // weight: (feature) => feature.get('weight'),
    });

    map.addLayer(heatmap);

    return () => {
      map.removeLayer(heatmap);
      heatmap.dispose();
    };
  }, [features, map]);

  return null;
}
