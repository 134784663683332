import { AxiosError } from "axios";

export enum ModuleType {
  playback = 'playback',
  live = 'live',
  staticAnalysis = 'staticAnalysis',
  dashboard = 'dashboard',
  sms = 'sms',
};

interface ApiErrorData {
  message: string;
}

export interface ApiError extends AxiosError<ApiErrorData> { }

export interface Module {
  playback: boolean;
  live: boolean;
  staticAnalysis: boolean;
  dashboard: boolean;
  sms: boolean;
}

export interface Config {
  airport?: string;
  maxAllowedTimeRangeInterval?: {
    playbackPage?: number;
    staticAnalysisPage?: number;
  };
  module?: Module;
}

export enum NoFaultType {
  low = 'LOW',
  medium = 'MEDIUM',
  high = 'HIGH',
  noFault = 'NOFAULT',
  Mechanical = 'MECHANICAL',
  TBD = 'TBD'
};

export interface Access { AI: boolean, GHI: boolean, OTP: boolean, BHS: boolean }

export enum AccessType {
  AI = 'AI',
  GHI = 'GHI',
  OTP = 'OTP',
  BHS = 'BHS',
};
