import { TextField } from "@mui/material";
import { DateTimePicker as MUIDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Control, Controller } from "react-hook-form";
import { BaseField } from "./types";

interface IDatePicker extends Omit<BaseField, "control"> {
  control?: Control<any>;
  error?: string;
}

export default function DateTimePicker({
  control,
  defaultValue,
  disabled,
  error,
  fullWidth,
  id,
  label,
  rules,
}: IDatePicker) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? null}
      name={id}
      render={({ field }) => (
        <MUIDateTimePicker
          {...field}
          ampm={false}
          disabled={disabled}
          disableFuture
          InputProps={{ id }}
          label={label}
          onClose={field.onBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              fullWidth={fullWidth}
              helperText={error}
              onBlur={field.onBlur}
            />
          )}
          showToolbar
        />
      )}
      rules={rules}
    />
  );
}
