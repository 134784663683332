import { IconButton, styled, Tooltip } from "@mui/material";
import { StyledTypography } from "../styled";
import Logout from "@mui/icons-material/Logout";
import { useAuthContext } from "../../context/Auth";

const StyledIconButton = styled(IconButton)`
  border-radius: 0;
  margin-bottom: 48px;
  margin-top: auto;
  padding: 10px;
  width: 100%;
  font-size: 18px;
  color: #c8c8c8;

  svg {
    fill: #fff;
    font-size: 28px;
  }
`;

interface ISidebarProfileButton {
  expanded: boolean;
}

export default function SidebarProfileButton({
  expanded,
}: ISidebarProfileButton) {
  const { logout } = useAuthContext();

  return (
    <Tooltip enterDelay={500} placement="right" title="Log Out">
      <StyledIconButton aria-label="user menu" onClick={logout} size="large">
        <StyledTypography expanded={expanded}>
          {expanded && <span>Log Out</span>}
          <Logout />
        </StyledTypography>
      </StyledIconButton>
    </Tooltip>
  );
}
