import { useCallback, useEffect, useState } from "react";
import { ApiError } from "../types/api";
import { TargetsAtSourceTimestamp } from "../types/map";
import { useAuthContext } from "../context/Auth";

interface LiveTracksData {
  clearErrorMsg: () => void;
  errorMsg: string | null;
  tracks: TargetsAtSourceTimestamp[] | null;
}

export default function useLiveTracks(): LiveTracksData {
  const { socket } = useAuthContext();
  const [tracks, setTracks] = useState<TargetsAtSourceTimestamp[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  function onDataFetched(newTrack: TargetsAtSourceTimestamp) {
    setTracks(previous => previous.concat(newTrack));
  }

  useEffect(() => {
    function onError(errMsg: string) {
      return setErrorMsg(errMsg);
    }

    socket.on('live:data', onDataFetched);
    socket.on('live:error', onError);

    return () => {
      socket.off('live:data', onDataFetched);
      socket.off('live:error', onError);
    };
  }, [])

  const clearErrorMsg = useCallback(() => setErrorMsg(null), []);

  return {
    clearErrorMsg,
    errorMsg,
    tracks,
  };
}
