import axios, { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { Config } from "../types/api";
import { MapConfig } from "../types/map";
import { User, UserLogin } from "../types/user";
import { TimeFormat } from "../types/chart";
import {
  AMS,
  SmsAnalyticsResponse,
  AnalyticsCommonResponse,
  AMSReturnType,
  AMSPostType
} from "../types/sms";
import { ReportRequest } from "../types/report";
import { downloadResponse } from "../utils/api";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export function useInterceptors(clearSession: () => void) {
  const handleError = useCallback(
    (error: AxiosError) => {
      if (
        error?.isAxiosError &&
        error?.response?.status === 401 &&
        error?.config?.url !== "/dashboard-backend/logout" &&
        error?.config?.url !== "/dashboard-backend/login"
      ) {
        clearSession();
      }

      return Promise.reject(error);
    },
    [clearSession]
  );

  useEffect(() => {
    const interceptor = client.interceptors.response.use(
      undefined,
      handleError
    );

    return () => {
      client.interceptors.response.eject(interceptor);
    };
  }, [handleError]);
}

// AUTH
export async function login(data: UserLogin) {
  const { data: { data: responseData } } = await client.post<{ data: User & Config }>("/dashboard-backend/login", data);
  const { username, access, id, idleTimeInSec, ...rest } = responseData;

  return { user: { username, access, id, idleTimeInSec }, config: rest };
}

export async function logout() {
  await client.post("/dashboard-backend/logout");
}

export async function refresh() {
  await client.post("/dashboard-backend/refreshToken");
}

export async function getMap() {
  const res = await client.get<{ data: MapConfig }>("/dashboard-backend/getMap");
  return res.data.data;
}

export async function getAMS() {
  const res = await client.get<{ data: AMSReturnType }>(
    "/dashboard-backend/analytics/ams"
  );
  return res.data.data;
}

export async function getSMSCompanies() {
  const res = await client.get<{ data: string[] }>(
    "/dashboard-backend/analytics/sms/companies",
  );
  return res.data.data;
}

export async function uploadSMS(
  file: File,
): Promise<void> {
  const form = new FormData();
  form.set("data", file);

  await client.post("/dashboard-backend/analytics/sms", form);
}

export async function getKpiSmsData(timeStartEpoch: number, timeEndEpoch: number, timeFormat: TimeFormat): Promise<SmsAnalyticsResponse> {
  const res = await client.get<SmsAnalyticsResponse>(
    "/dashboard-backend/analytics/sms/analytics",
    {
      params: {
        timeStartEpoch,
        timeEndEpoch,
        timeFormat,
      }
    }
  );
  return res.data;
}

export async function getKpiOtpData(timeStartEpoch: number, timeEndEpoch: number, timeFormat: TimeFormat): Promise<AnalyticsCommonResponse> {
  const res = await client.get<AnalyticsCommonResponse>(
    "/dashboard-backend/analytics/otp/analytics",
    {
      params: {
        timeStartEpoch,
        timeEndEpoch,
        timeFormat,
      }
    }
  );
  return res.data;
}

export async function getKpiBhsData(timeStartEpoch: number, timeEndEpoch: number, timeFormat: TimeFormat): Promise<AnalyticsCommonResponse> {
  const res = await client.get<AnalyticsCommonResponse>(
    "/dashboard-backend/analytics/bhs/analytics",
    {
      params: {
        timeStartEpoch,
        timeEndEpoch,
        timeFormat,
      }
    }
  );
  return res.data;
}

export async function downloadReportPdf(
  report: ReportRequest,
) {
  const resp = await client.post(
    "/dashboard-backend/report/pdf",
    report,
    {
      responseType: 'arraybuffer'
    }
  );

  downloadResponse(`${report.title}.pdf`, resp);
}

export async function updateSMS(
  data: AMSPostType
): Promise<void> {
  try {
    await client.put<{ data: AMS[] }>(
      "/dashboard-backend/analytics/sms",
      { data: [data] }
    );
  } catch (error) {
    console.error('Error updating SMS data:', error);
    throw error;
  }
}

export async function updateBHS(
  data: AMSPostType
): Promise<void> {
  try {
    await client.put<{ data: AMS[] }>(
      "/dashboard-backend/analytics/bhs",
      { data: [data] }
    );
  } catch (error) {
    console.error('Error updating BHS data:', error);
    throw error;
  }
}

export async function updateOTP(
  data: AMSPostType
): Promise<void> {
  try {
    await client.put<{ data: AMS[] }>(
      "/dashboard-backend/analytics/otp",
      { data: [data] }
    );
  } catch (error) {
    console.error('Error updating OTP data:', error);
    throw error;
  }
}
