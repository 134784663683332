import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Map, PlaybackMarkers } from "../../components/map";
import { PageContainer } from "../../components/styled";
import Toast from "../../components/Toast";
import { FloatingLeftGroup, FloatingBottomRightGroup } from "../../components/styled/FloatingGroups";
import { useAuthContext } from "../../context/Auth";
import useLiveTracks from "../../hooks/useLiveTracks";
import { format } from "date-fns";

export default function LivePage() {
  const { socket } = useAuthContext();
  const { clearErrorMsg, errorMsg, tracks } = useLiveTracks();
  const [connectMsg, setConnectMsg] = useState<string | null>(null);

  const clearConnectMsg = useCallback(() => setConnectMsg(null), []);

  useEffect(() => {
    function onConnect() {
      return setConnectMsg("Connection established, beginning stream!");
    };

    function onDisconnect() {
      return setConnectMsg(`Connection terminated.`);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.connect();

    return () => {
      socket.disconnect();
      socket.off('connection', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  return (
    <PageContainer>
      <Map>
        {tracks &&
          <PlaybackMarkers
            tracks={tracks.flatMap((t) => t.Targets)}
          />
        }
        <FloatingLeftGroup>
          <Typography>
            Time being displayed: {format(new Date(), "Ppp")}
          </Typography>
        </FloatingLeftGroup>
        <FloatingBottomRightGroup>
          <Typography>
            Hold Shift + Alt while dragging to rotate the map
            <br />
            Press the compass arrow to reset view to North
          </Typography>
        </FloatingBottomRightGroup>
        <Toast message={errorMsg} onClose={clearErrorMsg} />
        <Toast message={connectMsg} onClose={clearConnectMsg} severity="success" />
      </Map>
    </PageContainer>
  );
}
